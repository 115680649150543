import tw, { css, styled, theme } from 'twin.macro'
import BackgroundImage from "gatsby-background-image"

const BackgroundContainer = styled(BackgroundImage)(() => [
    tw`flex flex-col justify-start w-full h-full sm:justify-center`,
    css`
        color: ${theme`colors.white`};
        background-color: ${theme`colors.dark_blue`};
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;        
    `,
])

export default BackgroundContainer