import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import 'twin.macro'

export default function Home() {
  return (
    <Layout>
        <SEO title="Home" />
        <div tw="relative py-3 sm:w-2/3">
          <div tw="relative px-4 sm:p-10 md:p-20 lg:px-32">
            <div tw="mx-auto">
                <div tw="py-8 text-base leading-6 space-y-4 text-white sm:text-lg sm:leading-7 tracking-wider">
                  <p tw="text-2xl font-bold pb-2">Welkom bij AMIKO.</p>
                  <p tw="py-5">Onze website staat momenteel in de steigers.</p>
                  <p tw="text-white text-opacity-75 text-sm">Huidige status:</p>
                  <ul tw="list-disc space-y-2 text-sm text-white text-opacity-75">
                    <li tw="flex items-start">
                      <span tw="h-6 flex items-center sm:h-7">
                        <svg tw="flex-shrink-0 h-7 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                      </span>
                      <p tw="ml-2 leading-7 line-through">Rebranding</p>
                    </li>
                    <li tw="flex items-start">
                      <span tw="h-6 flex items-center sm:h-7">
                        <svg tw="flex-shrink-0 h-7 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                      </span>
                      <p tw="ml-2 leading-7 line-through">
                        Tijdelijke 'under construction' website
                      </p>
                    </li>
                    <li tw="flex items-start">
                      <span tw="h-6 flex items-center sm:h-7">
                        <svg tw="flex-shrink-0 h-7 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="nonzero" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                      </span>
                      <p tw="ml-2 leading-7">Wireframing & prototyping</p>
                    </li>
                    <li tw="flex items-start">
                      <span tw="h-6 flex items-center sm:h-7">
                        <svg tw="flex-shrink-0 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="nonzero" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                      </span>
                      <p tw="ml-2 leading-7">Ontwikkeling nieuwe website</p>
                    </li>
                    <li tw="flex items-start">
                      <span tw="h-6 flex items-center sm:h-7">
                        <svg tw="flex-shrink-0 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="nonzero" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                      </span>
                      <p tw="ml-2 leading-7">Launch</p>
                    </li>
                  </ul>
                </div>
                <div tw="pt-6 text-sm leading-6">
                  <p>
                    Contact: <a href="mailto:info@amiko.be" title="Neem vrijblijvend contact op." tw="text-white text-opacity-75 hover:text-opacity-100">info@amiko.be</a>
                  </p>
                </div>
            </div>
          </div>
        </div>
    </Layout>
  );

}
