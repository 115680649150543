import React from 'react'
import { GlobalStyles } from 'twin.macro'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundContainer from "./background-container"

const Layout = ({ children }) => {

    const imageData = useStaticQuery(graphql`
        query imageDataQuery {
            backgroundImage: file(relativePath: { eq: "bg-construction.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 3000){
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);  

    return (
        <div tw="flex flex-col items-center justify-center h-screen font-mono">
            <GlobalStyles />
            <BackgroundContainer
                fluid={imageData.backgroundImage.childImageSharp.fluid}
            >
                {children}
            </BackgroundContainer>
        </div>
    );
}

export default Layout
